import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { InsertionStatus } from '../../models/insertion-status.model';
import { ScoreOrderReport, ScoreOrderValidation } from '../../models/score-order.model';
import { CustomerLink } from '../../models/settings.model';
import { SettingsService } from '../../services/settings.service';

import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmRescheduleLinkSettings, DateRange, LinkType, ScheduleLinkSettings, State } from '@antsway/antsroute-common';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { CountdownEvent } from 'ngx-countdown/interfaces';
import { ScoringService } from '../../services/scoring.service';

@Component({
  selector: 'ar-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {
  date: Date;
  minDate: Date;

  scoreOrderReport: ScoreOrderReport;
  selectedStatus: InsertionStatus;
  countdownConfig = {};

  step: 'ERROR';

  scoreInProgress = false;
  validating = false;
  @Input() config: CustomerLink<ConfirmRescheduleLinkSettings | ScheduleLinkSettings>;
  @Input() linkType: LinkType;
  @Output() isScoreValidated: EventEmitter<boolean> = new EventEmitter<boolean>();
  LinkType = LinkType;
  state: State;

  constructor(
    protected translateService: TranslateService,
    private scoringService: ScoringService,
    public settingsService: SettingsService<ConfirmRescheduleLinkSettings | ScheduleLinkSettings>,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);
    // Date selected by default = Today + 3 days
    const date = new Date();
    date.setDate(date.getDate() + 3);
    this.date = date;

    // Min date = tomorrow
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    this.minDate = minDate;

    // Datepicker adapt language
    this._locale = this.settingsService.language?.id ?? 'fr';
    this._adapter.setLocale(this._locale);
    this._adapter.getFirstDayOfWeek = (): number => 1;
  }

  ngOnInit(): void {
    // Get the earliest date of the possibleDates, it will be the selection of the calendar
    if (this.config['possibleDates']?.length > 0) {
      let earliestDate = this.config['possibleDates'][0].startDate;
      this.config['possibleDates'].forEach((dateRange: DateRange) => {
        if (dayjs(dateRange.startDate).isBefore(earliestDate, 'day')) {
          earliestDate = dateRange.startDate;
        }
      });
      this.date = earliestDate;
    }

    this.state = this.config.order.state;
    // Init date selected
    this.selectDate(this.date);
  }

  selectDate(date: Date): void {
    this.scoreInProgress = true;

    // Get date with format YYYY-MM-DD
    const dateFormatted = dayjs(date).format('YYYY-MM-DD');
    this.resetScore(true);
    this.scoringService
      .scoreOrder(this.settingsService.token, dateFormatted, this.settingsService.link.linkSettings.type)
      .subscribe((res) => {
        res.status = res.status
          .filter((s) => s.reason === null)
          .sort((a, b) => {
            if (a.timeSlot.from === b.timeSlot.from) {
              return a.timeSlot.to.localeCompare(b.timeSlot.to);
            } else {
              return a.timeSlot.from.localeCompare(b.timeSlot.from);
            }
          });
        this.scoreOrderReport = res;
        this.countdownConfig = { format: 'mm:ss', stopTime: res.timeoutTimestamp };
      })
      .add(() => (this.scoreInProgress = false));
  }

  /**
   * Reset reschedule when selection is expired (at the end of the countdown)
   */
  resetScore(dateChanged?: boolean): void {
    if (this.scoreOrderReport != null) {
      if (!dateChanged) {
        this.date = null;
      }

      this.scoringService
        .discardScoreOrder(
          this.settingsService.token,
          this.scoreOrderReport.uniqueOrderScoreIdentifier,
          this.settingsService.link.linkSettings.type
        )
        .subscribe();
    }
    this.scoreOrderReport = null;
    this.selectedStatus = null;
  }

  handleCountDownEvent(event: CountdownEvent): void {
    if (event.action === 'done') {
      this.resetScore();
    }
  }

  selectTimeSlot(status: InsertionStatus): void {
    if (status == null) {
      this.selectedStatus = null;
    } else {
      this.selectedStatus = status;
    }
  }

  dateFilter = (d: Date): boolean => {
    if (this.config['possibleDates'] && this.config['possibleDates'].length > 0) {
      const dayjsObj = dayjs(d);
      return this.config['possibleDates'].some(
        (dateRange: DateRange) => dayjsObj.isSameOrAfter(dateRange.startDate, 'day') && dayjsObj.isSameOrBefore(dateRange.endDate, 'day')
      );
    }

    return true;
  };

  validateScore(): void {
    this.validating = true;
    const validation = new ScoreOrderValidation();

    validation.date = dayjs(this.date).format('YYYY-MM-DD');
    validation.timeSlot = this.selectedStatus.timeSlot;
    validation.agent = this.selectedStatus.agent;
    validation.uniqueOrderScoreIdentifier = this.scoreOrderReport.uniqueOrderScoreIdentifier;

    this.scoringService.validateScoreOrder(this.settingsService.token, validation, this.settingsService.link.linkSettings.type).subscribe({
      next: (order) => {
        this.config.order = order;
        this.isScoreValidated.emit(true);
      },
      error: () => {
        this.step = 'ERROR';
        this.validating = false;
      }
    });
  }

  cancel(): void {
    if (this.scoreOrderReport != null) {
      this.scoringService
        .discardScoreOrder(
          this.settingsService.token,
          this.scoreOrderReport.uniqueOrderScoreIdentifier,
          this.settingsService.link.linkSettings.type
        )
        .subscribe(() => this.isScoreValidated.emit(false));
    }
  }
}
