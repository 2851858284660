<form class="custom-input" [formGroup]="form">
  <i class="las la-phone custom-input-icon"></i>
  <input formControlName="phone" id="mobileNumber" minlength="0" maxlength="20" />
</form>

@if (isInvalid) {
  @if (nationalMode) {
    <span class="error">{{ 'error.phoneNumber' | translate }}</span>
  }
  @if (!nationalMode) {
    <span class="error">{{ 'error.mobileNumber' | translate }}</span>
  }
  @if (strict && !nationalMode && phone.hasError('formatError')) {
    <span class="error">{{ 'error.mobileNumberExpected' | translate }}</span>
  }
}
