import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fr, Language, languages } from '../../models/language.model';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  languages = languages;
  selectedLanguage: Language;
  @Input() selectedLanguageId = fr.id;
  @Output() returnId: EventEmitter<Language> = new EventEmitter<Language>();

  constructor() {}

  ngOnInit(): void {
    this.selectedLanguageId = this.selectedLanguageId.substring(0, 2);
    this.selectedLanguage = this.getLanguageFromId(this.selectedLanguageId);
  }

  getLanguageFromId(id: string): Language {
    return this.languages.find((value) => value.id === id);
  }

  updateSelectedLanguage(matSelectChange: Language): void {
    if (matSelectChange) {
      this.selectedLanguageId = matSelectChange.id;
      this.selectedLanguage = matSelectChange;
      this.returnId.emit(matSelectChange); // To update other component about the language change
    }
  }
}
