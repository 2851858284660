<form class="custom-input" [formGroup]="form">
  <i class="las la-map-marker-alt custom-input-icon"></i>
  <input required formControlName="address" placeholder="{{ 'common.address' | translate }}" [matAutocomplete]="auto" />
</form>
<mat-autocomplete #auto="matAutocomplete">
  @for (location of locations; track location) {
    <mat-option [value]="location.description" (onSelectionChange)="selectFromPredictions(location)">
      <!--    <mat-icon>location_on</mat-icon>-->
      <i class="las la-map-marker-alt" style="margin-right: 16px"></i>
      <span style="font-size: 14px">{{ location.description }}</span>
    </mat-option>
  }
</mat-autocomplete>

@if (form.get('address').invalid && (form.get('address').touched || form.get('address').dirty)) {
  @if (form.get('address').hasError('required')) {
    <span class="error">{{ 'common.required' | translate }}</span>
  }
  @if (!form.get('address').hasError('required') && form.get('address').hasError('unknownAddress')) {
    <span class="error">{{ 'common.unknown' | translate }}</span>
  }
}
