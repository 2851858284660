import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import IntlTelInput from 'intl-tel-input';
import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js';
import { distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() form: FormGroup;

  @Input() allowDropdown: boolean;
  @Input() disabled = false;
  @Input() strict = true;
  @Input() small = false;
  @Input() large = true;
  @Input() required = false;
  @Input() nationalMode = false;
  @Input() initialCountry = 'fr';
  @Input() number: string;
  @Output() numberChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() numberValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  isInvalid = false;

  interTelInput: IntlTelInput;
  input: HTMLElement;

  private phoneChangeSubscription: Subscription;

  ngOnInit(): void {
    this.phone.addValidators(this.phoneValidator());
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.init());

    this.phoneChangeSubscription = this.phone.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      let country = null;
      if (this.nationalMode) {
        country = this.interTelInput.getSelectedCountryData().iso2.toUpperCase();
      }

      if (this.phone.value === null || this.phone.value === undefined || this.phone.value === '') {
        this.isInvalid = false;
        this.phone.setErrors((this.phone.dirty || this.phone.touched) && this.required ? { required: this.required } : null);
      } else {
        // use parsePhoneNumberFromString() from libphonenumber-js library: less strict validation than libphonenumber used in intl-tel-input
        this.checkInvalid(this.phone.value, this.phone.dirty || this.phone.touched);
      }

      if (!this.isInvalid && this.phone.value !== '' && this.phone.value !== null) {
        this.interTelInput.setNumber(parseMin(this.phone.value, country).number);
      }
      this.number = this.phone.value;
      this.emit();
    });
  }

  ngOnDestroy(): void {
    this.phone.clearValidators();
    this.phoneChangeSubscription?.unsubscribe();
  }

  init(): void {
    this.input = document.getElementById('mobileNumber');
    if (this.input) {
      this.interTelInput = IntlTelInput(this.input, {
        placeholderNumberType: 'MOBILE',
        nationalMode: this.nationalMode,
        allowDropdown: this.allowDropdown,
        formatOnDisplay: true,
        initialCountry: this.initialCountry
      });
      this.input.addEventListener('countrychange', () => {
        this.checkInvalid(this.interTelInput.getNumber());
        this.emit();
      });
      if (this.number) {
        this.interTelInput.setNumber(this.number);
        this.emit();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.input && changes.initialCountry && changes.initialCountry.previousValue !== changes.initialCountry.currentValue) {
      this.initialCountry = changes.initialCountry.currentValue;
      this.init();
    }
  }

  checkInvalid(number: string, dirtyOrTouched = true): void {
    let country = null;
    if (this.nationalMode) {
      country = this.interTelInput.getSelectedCountryData().iso2.toUpperCase();
    }
    const mustValidate = dirtyOrTouched && number.length > 0 && this.strict;
    this.isInvalid = mustValidate && !!this.interTelInput && (!parseMin(number, country) || !parseMin(number, country).isValid());
    this.phone.updateValueAndValidity();
  }

  async emit(): Promise<void> {
    let requiredError = false;
    if (this.required && (this.number === null || this.number === '')) {
      requiredError = true;
    }
    this.numberValidChange.emit(!this.isInvalid && !requiredError);
    if (!this.isInvalid) {
      if (this.number === null) {
        this.numberChange.emit(null);
      } else {
        this.numberChange.emit(this.interTelInput.getNumber());
      }
    }

    // Force phone number format on display when editing (sometimes not working)
    if (this.number !== null) {
      setTimeout(() => this.interTelInput.setNumber(this.number));
    }
  }

  get phone(): AbstractControl {
    return this.form.get('phone');
  }

  phoneValidator(): ValidatorFn {
    return (_: AbstractControl) => {
      if (!this.number) {
        return null;
      }
      if (!this.number.startsWith('+')) {
        return { formatError: true };
      }
      return this.isInvalid ? { error: true } : null;
    };
  }
}
