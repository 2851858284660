import { Component } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { BaseLinkSettings } from '@antsway/antsroute-common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  constructor(public settingsService: SettingsService<BaseLinkSettings>) {}
}
