import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TerminologyPipe, toTitleCase } from '@antsway/antsroute-common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private terminology: TerminologyPipe
  ) {}

  alert(key: string): void {
    let text: string = '';

    text = toTitleCase(this.terminology.transform(this.translateService.instant(key)));

    this.snackBar.open(text, null, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }
}
