<button mat-icon-button [matMenuTriggerFor]="languageMenu" aria-label="Select language" fxLayoutAlign="center center">
  <img [src]="selectedLanguage.image" [alt]="selectedLanguage.alt" />
</button>
<mat-menu #languageMenu="matMenu">
  @for (language of languages; track language) {
    <button mat-menu-item (click)="updateSelectedLanguage(language)">
      <div fxLayoutAlign="start center" fxLayoutGap="10px">
        <img [src]="language.image" [alt]="language.alt" />
        <span>
          {{ language.text }}
        </span>
      </div>
    </button>
  }
</mat-menu>
