export class Language {
  fullId: string;
  id: string;
  text: string;
  image: string;
  alt?: string;
}

export const locales = {
  fr: 'fr-FR',
  en: 'en-US',
  es: 'es-ES',
  de: 'de-DE',
  it: 'it-IT'
};

export const fr = {
  id: 'fr',
  fullId: 'fr_FR',
  text: 'Français',
  image: 'assets/images/flags/France.png',
  alt: 'Drapeau Français'
} as Language;

export const en = {
  id: 'en',
  fullId: 'en_GB',
  text: 'English',
  image: 'assets/images/flags/United-Kingdom.png',
  alt: 'United-Kingdom flag'
} as Language;

export const es = {
  id: 'es',
  fullId: 'es_ES',
  text: 'Español',
  image: 'assets/images/flags/Spain.png',
  alt: 'Spain Language'
} as Language;

export const de = {
  id: 'de',
  fullId: 'de_DE',
  text: 'Deutsch',
  image: 'assets/images/flags/Germany.png',
  alt: 'Deutsch flag'
} as Language;

export const it = {
  id: 'it',
  fullId: 'it_IT',
  text: 'Italiano',
  image: 'assets/images/flags/Italia.png',
  alt: 'Italian flag'
} as Language;

export const languages = [en, fr, es, de, it];
