import { TimeSlot } from '@antsway/antsroute-common';
import { InsertionStatus } from './insertion-status.model';

export class ScoreOrderReport {
  uniqueOrderScoreIdentifier: string;
  timeoutTimestamp: number;
  status: InsertionStatus[];
}

export class ScoreOrderValidation {
  uniqueOrderScoreIdentifier: string;
  date: string;
  timeSlot: TimeSlot;
  agent: string;
}
