@if (settingsService.link.enterpriseContact) {
  <div class="contact" style="margin-bottom: 10px">
    <b>{{ 'common.anyQuestion' | translate }}</b>
    <div class="contact-info">
      @if (!!settingsService.link.enterpriseContact.phone) {
        <span class="icon-info">
          <i class="las la-phone"></i>
          <a class="underline" [href]="'tel:' + settingsService.link.enterpriseContact.phone">{{
            settingsService.link.enterpriseContact.phone
          }}</a>
        </span>
      }
      @if (!!settingsService.link.enterpriseContact.email) {
        <span class="icon-info">
          <i class="las la-envelope"></i>
          <a [href]="'mailto:' + settingsService.link.enterpriseContact.email" class="underline">{{
            settingsService.link.enterpriseContact.email
          }}</a>
        </span>
      }
    </div>
  </div>
}
