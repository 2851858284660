import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ConfirmRescheduleLinkSettings, CustomField, FieldType, ScheduleLinkSettings } from '@antsway/antsroute-common';
import { SettingsService } from '../../services/settings.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-edit-custom-field',
  templateUrl: './edit-custom-field.component.html',
  styleUrls: ['./edit-custom-field.component.scss']
})
export class EditCustomFieldComponent implements OnInit {
  @Input() name: string | number;
  @Input() form: FormGroup;
  @Input() fieldType: FieldType;
  @Input() field: CustomField;
  @Input() isEditable: boolean;
  @Input() isEditing: boolean = false;
  @Input() value: String;

  @Output() fieldChange: EventEmitter<[number, any]> = new EventEmitter<any>();

  @ViewChild('picker') datepicker: MatDatepicker<Date>;

  FieldType = FieldType;
  isFieldSelectType: boolean = false;

  constructor(
    public settingsService: SettingsService<ConfirmRescheduleLinkSettings | ScheduleLinkSettings>,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    this._locale = this.settingsService.language?.id ?? 'fr';
    this._adapter.setLocale(this._locale);
    this._adapter.getFirstDayOfWeek = (): number => 1;
  }

  ngOnInit() {
    this.isFieldSelectType = this.fieldType === FieldType.SELECT_UNIQUE || this.fieldType === FieldType.SELECT_MULTIPLE;
    if (this.isEditable) this.value = this.form.controls[this.name]?.value;
  }

  onFieldChange($event) {
    if (this.isEditable && this.isEditing) this.fieldChange.emit([this.field.id, $event.target.value]);
  }

  onBooleanFieldChange($event) {
    if (this.isEditable && this.isEditing) this.fieldChange.emit([this.field.id, $event.target.checked]);
  }

  onDateChange($event) {
    if (this.isEditable && this.isEditing) this.fieldChange.emit([this.field.id, dayjs($event.value).format('YYYY-MM-DD')]);
  }

  onSelectChange($event) {
    if (this.isEditable && this.isEditing) this.fieldChange.emit([this.field.id, $event.value]);
  }

  preventActionifNotEditable(event) {
    if (!this.isEditable && this.isEditing) event.preventDefault();
  }
}
