<!-- Uses the mat-card-header to fit in the mat-card -->
<mat-card-header ngClass.xs="sticky" class="header">
  <!-- Header Container -->
  <div class="header-container" fxLayout="row">
    <!-- For the Logo, it should be in the center of the header -->
    <div class="logo" fxFlexAlign="center" fxFlex="70" fxFlexOffset="15">
      @if (settingsService.link.imageUrl) {
        <img [src]="settingsService.link.imageUrl" alt="Enterprise's logo" height="50px" />
      }
    </div>

    <!-- For the language selector, it should be on the right of the header -->
    <div class="language-selector" fxFlex>
      <app-language-select fxFlexAlign="start" [selectedLanguageId]="settingsService.language.id" (returnId)="changeLanguage($event)">
      </app-language-select>
    </div>
  </div>
</mat-card-header>
