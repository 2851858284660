import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseLinkSettings, EnterpriseRegion, LinkType, StateService } from '@antsway/antsroute-common';
import * as L from 'leaflet';
import { LatLng, MapOptions, tileLayer } from 'leaflet';
import { SettingsService } from '../../services/settings.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit, OnInit {
  private agentAreaColor = this.settingsService.link.color;
  private map: L.Map;
  private agentOuterArea: L.Circle;
  private agentInnerArea: L.Circle;
  private customerMarker: L.Marker;

  options: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        detectRetina: true,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      })
    ],
    zoom: 15,
    zoomControl: true
  };
  agentCoordinates: L.LatLng;
  customerCoordinates: L.LatLng;

  constructor(
    public settingsService: SettingsService<BaseLinkSettings>,
    private stateService: StateService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    if (this.settingsService.link.region === EnterpriseRegion.AFRICA) {
      this.options.layers[0] = tileLayer('https://api.maptiler.com/maps/1f0023e9-5643-4efd-a820-3c778c3285b3/{z}/{x}/{y}.png?key=AjoSBYr2UN8Q9bwH4e1J', {
        maxZoom: 18,
        detectRetina: true,
        attribution:
          '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
      });
    }
  }

  ngAfterViewInit(): void {
    this.refreshMap();
    this.settingsService.$refreshMap.subscribe(() => {
      this.refreshMap();
    });
  }

  private setRadius(zoom: number): void {
    if (zoom !== Infinity && zoom < 18) {
      const s = 591657550.5 / Math.pow(2, zoom - 1);
      this.agentInnerArea.setRadius(s * 0.0018);
      this.agentOuterArea.setRadius(s * 0.005);
    } else {
      this.agentInnerArea.setRadius(180);
      this.agentOuterArea.setRadius(500);
    }
  }

  private refreshMap(): void {
    if (!this.map) this.map = L.map('mapId', this.options);

    this.refreshCustomerMarker();
    if (LinkType.TRACK === this.settingsService.link.linkSettings.type) this.refreshAgentArea();

    this.map.fitBounds(L.latLngBounds([this.customerCoordinates, this.agentCoordinates]));
    this.map.setMinZoom(2);
  }

  private refreshCustomerMarker(): void {
    if (this.customerMarker) {
      this.customerMarker.remove();
    }
    this.customerCoordinates = new LatLng(
      this.settingsService.link.order.location.latitude,
      this.settingsService.link.order.location.longitude
    );

    this.map.setView(this.customerCoordinates);
    this.httpClient.get('assets/images/location-icon.svg', { responseType: 'text' }).subscribe((value) => {
      const svgIcon = L.divIcon({ html: value, iconSize: [31, 43], iconAnchor: [15.5, 43] });
      this.customerMarker = L.marker(this.customerCoordinates, { icon: svgIcon }).addTo(this.map);
    });
  }

  private refreshAgentArea(): void {
    if (
      this.settingsService.link.lastKnownPosition &&
      this.stateService.isPlannedState(this.settingsService.link.order.state) &&
      this.settingsService.link.order.arrivalTimeLive
    ) {
      this.agentCoordinates = new LatLng(
        this.settingsService.link.lastKnownPosition.latitude,
        this.settingsService.link.lastKnownPosition.longitude
      );

      this.agentInnerArea = L.circle(this.agentCoordinates, {
        color: this.agentAreaColor,
        fillColor: this.agentAreaColor,
        fillOpacity: 1,
        radius: 500
      }).addTo(this.map);

      this.agentOuterArea = L.circle(this.agentCoordinates, {
        color: this.agentAreaColor,
        opacity: 0,
        fillColor: this.agentAreaColor,
        fillOpacity: 0.5,
        radius: 1000
      }).addTo(this.map);

      this.setRadius(this.map.getZoom());
      this.map.on('zoomend', (ev) => {
        this.setRadius(ev.target.getZoom());
      });
    }
  }
}
