import { Component } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { Language } from '../../models/language.model';
import { BaseLinkSettings } from '@antsway/antsroute-common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(public settingsService: SettingsService<BaseLinkSettings>) {}

  changeLanguage(lang: Language): void {
    this.settingsService.changeLanguage(lang.id);
  }
}
