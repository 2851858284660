@if (isEditing) {
  <form class="custom-input" [formGroup]="form" [class.select-field]="isFieldSelectType">
    <i class="las la-pen icon-custom" [class.select-field]="isFieldSelectType"></i>
    @switch (fieldType) {
      @case (FieldType.STRING) {
        <input [formControlName]="name" type="text" maxlength="254" (input)="onFieldChange($event)" />
      }
      @case (FieldType.INTEGER) {
        <input [formControlName]="name" type="number" max="2147483647" (input)="onFieldChange($event)" />
      }
      @case (FieldType.BOOLEAN) {
        <input
          [formControlName]="name"
          type="checkbox"
          (click)="preventActionifNotEditable($event)"
          (change)="onBooleanFieldChange($event)"
          class="boolean-input"
        />
      }
      @case (FieldType.DATE) {
        <input
          [formControlName]="name"
          type="text"
          matInput
          placeholder="{{ 'common.pickDate' | translate }}"
          [matDatepicker]="picker"
          (dateChange)="onDateChange($event)"
          (click)="datepicker.open()"
        />
        <mat-datepicker #picker></mat-datepicker>
      }
      @case (fieldType === FieldType.SELECT_UNIQUE || fieldType === FieldType.SELECT_MULTIPLE ? fieldType : '') {
        <div>
          <mat-form-field appearance="outline" class="mat-slim">
            <mat-select
              [formControlName]="name"
              [multiple]="fieldType === FieldType.SELECT_MULTIPLE"
              (selectionChange)="onSelectChange($event)"
              [placeholder]="field | customFieldLabel"
            >
              @if (fieldType === FieldType.SELECT_UNIQUE) {
                <mat-option>—</mat-option>
              }
              @for (option of field.selectOptions; track option) {
                <mat-option [value]="option">
                  {{ option }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
      @default {
        <input [formControlName]="name" readonly />
      }
    }
  </form>
}

@if (!isEditing) {
  <div class="ellipsis">
    @if (isEditable) {
      <i class="las la-pen"></i>
    }
    @if (!isEditable) {
      <i class="las la-database"></i>
    }
    <span matTooltip="{{ value }}" matTooltipClass="full-tooltip" matTooltipPosition="above">
      @if (field.type === FieldType.BOOLEAN) {
        {{ value ? ('common.yes' | translate) : ('common.no' | translate) }}
      }
      @if (field.type === FieldType.SELECT_MULTIPLE) {
        @if (!value || value.length === 0) {
          <i>{{ 'common.noValue' | translate }}</i>
        }
        @for (value of value; track value; let isLast = $last) {
          {{ value }}
          @if (!isLast) {
            ,
          }
        }
      }
      @if (field.type !== FieldType.BOOLEAN && field.type !== FieldType.SELECT_MULTIPLE) {
        @if (value) {
          {{ value }}
        }
        @if (!value) {
          <i>{{ 'common.noValue' | translate }}</i>
        }
      }
    </span>
  </div>
}
