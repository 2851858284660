import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LinkType, TimeSlot } from '@antsway/antsroute-common';
import { ScoreOrderReport } from '../models/score-order.model';
import { CustomerLinkOrder } from '../models/settings.model';

export class CustomerScoreValidation {
  uniqueOrderScoreIdentifier: string;
  date: string;
  timeSlot: TimeSlot;
  agent: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScoringService {
  constructor(private http: HttpClient) {}

  scoreOrder(token: string, date: string, linkType: LinkType): Observable<ScoreOrderReport> {
    const params = new HttpParams().set('token', token).set('date', date).set('linkType', linkType);
    return this.http.get<ScoreOrderReport>('/api/customer-links/score', { params });
  }

  validateScoreOrder(token: string, validation: CustomerScoreValidation, linkType: LinkType): Observable<CustomerLinkOrder> {
    const params = new HttpParams().set('token', token).set('linkType', linkType);
    return this.http.post<CustomerLinkOrder>('/api/customer-links/validate', validation, { params });
  }

  discardScoreOrder(token: string, uniqueOrderScoreIdentifier: string, linkType: LinkType): Observable<void> {
    const params = new HttpParams()
      .set('token', token)
      .set('uniqueOrderScoreIdentifier', uniqueOrderScoreIdentifier)
      .set('linkType', linkType);
    return this.http.delete<void>('/api/customer-links/discard', { params });
  }
}
