import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LinkType } from '@antsway/antsroute-common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private http: HttpClient) {}

  getOrderReport(token: string, linkType: LinkType, reportId: number, language: string): Observable<any> {
    return this.http.request(
      'GET',
      'api/customer-links/report/' + reportId + '?token=' + token + '&&type=' + linkType + '&&language=' + language,
      {
        responseType: 'arraybuffer',
        observe: 'response'
      }
    );
  }
}
