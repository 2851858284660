import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { Location } from '../../models/location.model';

// noinspection ES6UnusedImports
import {} from 'google.maps';

@Component({
  selector: 'app-location-finder',
  templateUrl: './location-finder.component.html',
  styleUrls: ['./location-finder.component.scss']
})
export class LocationFinderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Input() location: Location = new Location();
  @Input() form: FormGroup;
  @Input() disabled: boolean;

  @Output() locationChange = new EventEmitter<Location>();

  locationSelected = false;
  locations: google.maps.places.AutocompletePrediction[] = [];

  MIN_CHARACTERS = 3;

  private locationChangeSubscription: Subscription;

  private geocoder = new google.maps.Geocoder();

  constructor(private zone: NgZone) {}

  get address(): AbstractControl {
    return this.form.get('address');
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.form.get('address').disable();
    } else {
      this.form.get('address').addValidators(this.locationValidator());
    }
  }

  ngAfterViewInit(): void {
    this.locationChangeSubscription = this.address.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((input) => {
      if (!this.locationSelected) {
        if (input.length >= this.MIN_CHARACTERS) {
          this.getPredictions(input);
        } else {
          this.locations = [];
          this.location.latitude = null;
          this.location.longitude = null;
        }
      } else {
        // Fix autocomplete opened after selection
        this.locationSelected = false;

        this.locations = [];
        this.autocomplete.closePanel();
      }
    });
  }

  ngOnDestroy(): void {
    this.form.get('address').clearValidators();
    this.locationChangeSubscription?.unsubscribe();
  }

  locationValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      let locationFilled = false;

      if (
        (this.locationSelected && this.location.latitude && this.location.longitude && control.value) ||
        this.form.get('address').pristine
      ) {
        locationFilled = true;
      }

      return locationFilled ? null : { unknownAddress: true };
    };
  }

  private getPredictions(input: string): void {
    const autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      {
        input
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.zone.run(() => {
            this.locations = predictions;
            this.autocomplete.openPanel();
          });
        }
      }
    );
  }

  selectFromPredictions(place: google.maps.places.AutocompletePrediction): void {
    this.geocoder.geocode({ placeId: place.place_id }, (responses, status) => {
      if (status === google.maps.GeocoderStatus.OK && responses[0].formatted_address) {
        this.address.enable({ onlySelf: true, emitEvent: false });
        this.setLocation(responses[0].formatted_address, responses[0].geometry.location.lat(), responses[0].geometry.location.lng());
        this.locationSelected = true;
        this.locations = [];
        this.autocomplete.closePanel();
        this.address.updateValueAndValidity();
      }
    });
  }

  private setLocation(address: any, latitude: any, longitude: any): void {
    this.location.address = address;
    this.location.latitude = latitude;
    this.location.longitude = longitude;
    this.locationChange.next(this.location);
  }
}
