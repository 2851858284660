/**
 * Convert an hexadecimal color value to RGBA (with opacity option)
 * Source: https://stackoverflow.com/a/51564734
 * @param hex
 * @param alpha
 * @returns
 */
export const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};
